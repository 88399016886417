import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import SEO from '../../components/seo';
import Layout from '../../components/en';

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      excerpt
      frontmatter {
        title
        lead
        role
        facebook
        twitter
        instagram
        videos
        images {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        cover {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        avatar {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const User = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const { html, frontmatter, excerpt } = data.markdownRemark;
  const {
    title,
    cover,
    path,
    avatar,
    role,
    videos,
    images,
    facebook,
    twitter,
    instagram,
    lead,
  } = frontmatter;

  return (
    <Layout data={data} location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title={`${title} - Furlan Snowboards`}
        description={lead || excerpt || ' '}
        banner={cover}
        pathname={path}
        keywords={['snowboard', 'boards', 'versatility']}
      />
      <div className="userPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Img fluid={avatar.childImageSharp.fluid} alt="Furlan Snowboards" />
            </div>
            <div className="col-lg-12 mt-3">
              <h1>{title}</h1>
              <h4>{role}</h4>
              <p className="lead">
                <strong>{lead}</strong>
              </p>
              {!facebook === '' && (
                <a href={facebook} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-2x fa-facebook  mx-2" />
                </a>
              )}
              {!twitter === '' && (
                <a href={twitter} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-2x fa-twitter mx-2" />
                </a>
              )}
              {!instagram === '' && (
                <a href={instagram} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-2x fa-instagram mx-2" />
                </a>
              )}
              <div
                className="col-lg-12 mb-5"
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
              <div className="mt-5 mb-1">
                <Img fluid={cover.childImageSharp.fluid} alt="Furlan Snowboards" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="row mt-5">
              {videos &&
                videos.map((video, index) => (
                  <div
                    className="embed-responsive embed-responsive-16by9"
                    key={index}
                  >
                    <iframe
                      height="600"
                      width="100%"
                      title="video"
                      className="embed-responsive-item"
                      src={video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ))}
            </div>
            <div className="col-lg-12">
              <div className="row">
                {images &&
                  images.map((image, index) => (
                    <div className="col-md-6 p-1" key={index}>
                      <a
                        target="_blank"
                        href={image.childImageSharp.fluid.src}
                        rel="noopener noreferrer"
                      >
                        <Img fluid={image.childImageSharp.fluid} />
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container page-links">
          <div className="row">
            <div className="col-6">
              {prev && (
                <Link to={prev.frontmatter.path}>
                  Previous
                  <h3>{prev.frontmatter.title}</h3>
                </Link>
              )}
            </div>
            <div className="col-6 text-right">
              {next && (
                <Link to={next.frontmatter.path}>
                  Next
                  <h3>{next.frontmatter.title}</h3>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default User;

User.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};
